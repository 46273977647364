import React from "react";
import Logo from "./logos.png";

const Signature = ({ inputs }) => {
  return (
    <div id="signature">
      <table style={{ display: "block" }}>
        <tbody style={{ width: "506px" }}>
          <tr>
            <td
              style={{
                padding: "0 18px 0 5px",
                width: "40px",
                verticalAlign: "middle",
              }}
            >
              <img
                style={{
                  borderRadius: "100%",
                  width: "100px",
                  height: "100px",
                }}
                src={inputs.fileUrl}
                alt="Life Ride"
                title="Life Ride"
              />
            </td>
            <td style={{ verticalAlign: "middle" }}>
              <div
                style={{
                  height: 140,
                  width: 2,
                  background:
                    "linear-gradient(180deg, #E0281A 0%, #E01A73 100%) 0% 0% no-repeat padding-box",
                  padding: 0,
                }}
              />
            </td>
            <td style={{ padding: "0 0 0 20px", verticalAlign: "middle" }}>
              <span
                style={{
                  marginBottom: "2px",
                  lineHeight: "15px",
                  textAlign: "left",
                  font: "bold 16px sans-serif",
                  color: "#222222",
                  display: "block",
                }}
                id="name"
              >
                {inputs.name}
              </span>
              <span
                style={{
                  marginBottom: "8px",
                  lineHeight: "13px",
                  textAlign: "left",
                  font: "400 13px sans-serif",
                  letterSpacing: 0,
                  color: "#E0281A",
                  display: "block",
                }}
                id="position"
              >
                {inputs.role}
              </span>
              {(inputs.phone || inputs.mobilePhone) && (
                <div style={{ marginBottom: 5, lineHeight: "13px" }}>
                  <a
                    href={`tel:${inputs.phone}`}
                    target="_blank"
                    style={{
                      color: "#222222",
                      fontFamily: "sans-serif, sans-serif",
                      fontSize: 13,
                      lineHeight: "initial",
                      fontWeight: 400,
                      textDecoration: "none",
                      display: "inline-block",
                      marginBottom: "5px",
                    }}
                  >
                    <img
                      src="https://ik.imagekit.io/lifehealtgroup/asdas_e4Bqf2s1F"
                      alt="Life Health Group"
                      title="Life Health Group"
                      width={13}
                      style={{ marginRight: 5 }}
                    />
                    {inputs.phone}
                  </a>{" "}
                  |{" "}
                  <a
                    href={`tel:${inputs.mobilePhone}`}
                    target="_blank"
                    style={{
                      color: "#222222",
                      fontFamily: "sans-serif, sans-serif",
                      fontSize: 13,
                      lineHeight: "initial",
                      fontWeight: 400,
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src="https://ik.imagekit.io/lifehealtgroup/asdas_B2ZGfwi6c"
                      alt="Life Health Group"
                      title="Life Health Group"
                      width={10}
                      style={{ marginRight: 5 }}
                    />
                    {inputs.mobilePhone}
                  </a>
                </div>
              )}
              <a
                href={`https://${inputs.redirectLink}`}
                target="_blank"
                style={{
                  color: "#222222",
                  fontFamily: "sans-serif, sans-serif",
                  fontSize: 13,
                  lineHeight: "initial",
                  fontWeight: 400,
                  textDecoration: "none",
                  display: "inline-block",
                  marginBottom: 8,
                  display: "block",
                }}
              >
                <img
                  src="https://ik.imagekit.io/lifehealtgroup/Description_Ocsyb_Ypd"
                  alt="Url Icon"
                  width={14}
                  style={{ marginRight: 5 }}
                />
                {inputs.redirectLink}
              </a>
              <span
                style={{
                  color: "#222222",
                  fontFamily: "sans-serif, sans-serif",
                  fontSize: 13,
                  lineHeight: "initial",
                  fontWeight: 400,
                  display: "inline-block",
                  marginBottom: 8,
                  display: "block",
                }}
              >
                <img
                  src="https://ik.imagekit.io/lifehealtgroup/sadas_7bmgvmJp-"
                  alt="Map icon"
                  width={13}
                  style={{ marginRight: 5 }}
                />
                {inputs.address}
              </span>
              {inputs.addresOptional !== "" && (
                <span
                  style={{
                    color: "#222222",
                    fontFamily: "sans-serif, sans-serif",
                    fontSize: 13,
                    lineHeight: "initial",
                    fontWeight: 400,
                    display: "block",
                    marginLeft: 18,
                  }}
                >
                  {inputs.addresOptional}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              <a
                href="https://lifehealthgroup.com/"
                target="_blank"
              >
                <img
                  src="https://www.homecare.life/images/logos.png"
                  alt="Life Health Group"
                  title="Life Health Group"
                  width={400}
                  style={{ marginTop: 16, marginLeft: 20 }}
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ fontSize: 9, color: "#464748", marginTop: 20 }}>
        This electronic email and any attachments are intended to be for the use
        of the named recipient, and may contain information that is confidential
        or privileged. This communication may contain protected health
        information (PHI) that is legally protected from inappropriate
        disclosure by the Privacy Standards of the Health Insurance Portability
        and Accountability Act (HIPAA) and relevant New Jersey and Delaware
        Laws. If you are not the intended recipient, please note that any
        dissemination, distribution or copying of this communication is strictly
        prohibited. If you have received this message in error, you should
        notify the sender immediately by telephone or by return e-mail and
        delete and destroy all copies of this message.
      </p>
    </div>
  );
};

export default Signature;
