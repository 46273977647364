import React from 'react'
import { SignatureFormContainer, Title, Form, InputContainer, Label, Input, Button} from './styles'

const SignatureForm = ({handleOnSubmit, handleChange, inputs}) => {

    return(
        <SignatureFormContainer>
            <Title>Signature form</Title>
            <Form onSubmit={handleOnSubmit}>
                <InputContainer>
                    <Label for='name'>Name</Label>
                    <Input id='name' name='name' value={inputs.name || ""} onChange={handleChange} required/>
                </InputContainer>
                <InputContainer>
                    <Label for='role'>Role</Label>
                    <Input id='role' name='role' value={inputs.role || ""} onChange={handleChange} required/>
                </InputContainer>
                <InputContainer>
                    <Label for='phone'>Phone</Label>
                    <Input id='phone' name='phone' value={inputs.phone || ""} onChange={handleChange}/>
                </InputContainer>
                <InputContainer>
                    <Label for='mobilePhone'>Mobile phone</Label>
                    <Input id='mobilePhone' name='mobilePhone' value={inputs.mobilePhone || ""} onChange={handleChange}/>
                </InputContainer>
                <InputContainer>
                    <Label for='addres'>Address</Label>
                    <select id='address' name='address' value={inputs.address || ""} onChange={handleChange} required >
                        <option value="" selected></option>
                        <option value="70 South Orange Ave, Suite 220, Livingston, NJ 07039" selected>70 South Orange Ave, Suite 220, Livingston, NJ 07039</option>
                        <option value="139 Harristown Rd, Suite 101B, Glen Rock, NJ 07452">139 Harristown Rd, Suite 101B, Glen Rock, NJ 07452</option>
                        <option value="680 Broadway Paterson NJ 07514">680 Broadway Paterson NJ 07514</option>
                        <option value="74 Brick Blvd Brick NJ 08723">74 Brick Blvd Brick NJ 08723</option>
                        <option value="80 Cottontail Ln Somerset NJ 08873">80 Cottontail Ln Somerset NJ 08873</option>
                    </select>
                </InputContainer>
                <InputContainer>
                    <Label for='addresOptional'>Address optional</Label>
                    <select id='addressOptional' name='addresOptional' value={inputs.addresOptional || ""} onChange={handleChange} >
                        <option value="" selected></option>
                        <option value="70 South Orange Ave, Suite 220, Livingston, NJ 07039" selected>70 South Orange Ave, Suite 220, Livingston, NJ 07039</option>
                        <option value="139 Harristown Rd, Suite 101B, Glen Rock, NJ 07452">139 Harristown Rd, Suite 101B, Glen Rock, NJ 07452</option>
                        <option value="680 Broadway Paterson NJ 07514">680 Broadway Paterson NJ 07514</option>
                        <option value="74 Brick Blvd Brick NJ 08723">74 Brick Blvd Brick NJ 08723</option>
                        <option value="80 Cottontail Ln Somerset NJ 08873">80 Cottontail Ln Somerset NJ 08873</option>
                    </select>
                </InputContainer>
                <InputContainer>
                    <Label for='addres'>Redirect Link</Label>
                    <select id='redirectLink' name='redirectLink' value={inputs.redirectLink || ""} onChange={handleChange} required >
                        <option value="" selected></option>
                        <option value="www.homecare.life" selected>www.homecare.life</option>
                        <option value="www.ride.life">www.ride.life</option>
                        <option value="www.mydme.life">www.mydme.life</option>
                        <option value="www.hometherapy.life">www.hometherapy.life</option>
                        <option value="www.hospicecare.life ">www.hospicecare.life</option>
                    </select>
                </InputContainer>
                <InputContainer>
                    <Label for='photo'>Photo</Label>
                    <Input type='file' id='photo' name='photo' accept="image/png, image/jpeg" value={inputs.photo || ""} onChange={handleChange} required />
                </InputContainer>
                <Button type='submit'>Submit</Button>
            </Form>    
        </SignatureFormContainer>
    )
}

export default SignatureForm